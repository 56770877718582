<template>
    <svg
        width="49"
        height="48"
        viewBox="0 0 49 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            x="0.666504"
            width="48"
            height="48"
            rx="24"
            fill="#FF690C"
        />
        <path
            d="M21.6665 29.5H16.1665M19.1665 24H14.6665M21.6665 18.5H16.6665M29.6665 15L23.0701 24.235C22.7781 24.6438 22.6321 24.8481 22.6384 25.0185C22.6439 25.1669 22.7151 25.3051 22.8326 25.3958C22.9676 25.5 23.2187 25.5 23.7211 25.5H28.6665L27.6665 33L34.2629 23.765C34.5549 23.3562 34.7009 23.1519 34.6946 22.9815C34.6891 22.8331 34.6179 22.6949 34.5004 22.6042C34.3654 22.5 34.1143 22.5 33.612 22.5H28.6665L29.6665 15Z"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>

<script>
export default {
    name: 'Delivery'
}
</script>
<style scoped lang='scss'>
svg {
    width: 48px;
    height: 48px;
}
svg rect {
    fill: $main-color;
}
</style>
