<template>
    <div
        ref="banner"
        :class="!isWriterAccountDetails ? 'hire-baner' : 'hire-baner-account'"
    >
        <transition name="fade">
            <div
                v-if="showWriter"
                class="hire-baner__writer"
            >
                <avatar
                    :avatar-url="writer.avatar_url"
                    :avatar-fallback="writer.avatar_fallback"
                    :name="writer.name"
                    :user="'writer'"
                />
            </div>
        </transition>
        <div class="hire-baner__writer--mobile">
            <avatar
                :avatar-url="writer.avatar_url"
                :avatar-fallback="writer.avatar_fallback"
                :name="writer.name"
                :user="'writer'"
            />
        </div>
        <p class="hire-baner__title">
            Hire <span class="hire-baner__title-writer-name">{{ writer.name }}</span> in just a few clicks!
        </p>
        <ul class="hire-baner__list">
            <li class="hire-baner__list-item">
                <check-circle />
                It takes only a minute to register and submit your first order
            </li>
            <li class="hire-baner__list-item">
                <check-circle />
                Our service comes with 100% satisfaction guarantee
            </li>
            <li class="hire-baner__list-item">
                <check-circle />
                100% confidentiality & anonymity
            </li>
        </ul>
        <a
            :href="`/order/standard?writer=${writer.id}`"
            class="btn-base btn-main hire-baner__btn"
        >
            hire writer
        </a>
    </div>
</template>
<script>
import CheckCircle from '@/components/icons/CheckCircle.vue'
import Avatar from '@/components/common/Avatar.vue'

export default {
    name: 'HireWriter',
    components: {
        CheckCircle,
        Avatar
    },
    props: {
        writer: {
            type: Object
        }
    },
    data() {
        return {
            showWriter: false,
            bannerHeight: null
        }
    },
    computed: {
        isWriterAccountDetails() {
            return this.$route.name === 'writer_account_reviews' || this.$route.name === 'writer_account_about'
        }
    },
    mounted() {
        this.bannerHeight = this.$refs.banner.clientHeight
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll() {
            window.scrollY >= this.bannerHeight - 150 ? this.showWriter = true : this.showWriter = false
        }
    }
}
</script>
<style lang="scss">
.hire-baner{
    position: relative;
    width: 295px;
    align-self: start;
    padding: 20px;
    border: 2px solid $main-color;
    border-radius: $main-border-radius;
    box-shadow: 4px 8px 40px rgba(0, 0, 0, .05);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: $white;
    @media (max-width:992px) {
        width: 100%;
        padding: 25px;
        margin-bottom: 20px;
    }
    @media (max-width:480px) {
        padding: 20px;//25px 25px 107px;
    }
    &__title{
        font: 700 20px $font-family-base;
        text-align: center;
        line-height: 28px;
    }
    &__title-writer-name {
        color: $main-color;
    }
    &__list{
        margin-top: 20px;
        display: grid;
        gap: 20px;
        @media (max-width:992px) {
            margin: 20px auto 0;
        }
        &-item{
            display: flex;
            line-height: 20px;
            font-weight: 400;
            font-size: 14px;
            >svg{
                flex-shrink: 0;
                margin-right: 14px;
            }
        }
    }
    &__btn{
        margin-top: 30px;
        width: 90%;
        display: flex;
        align-self: center;
        @media (max-width:  992px) {
            width: 100%;
        }
    }
    &__writer {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
        .avatar-img {
            width: 120px;
            height: 120px;
        }
        @media (max-width:  768px) {
            display: none;
        }
    }
    &__writer--mobile {
        display: none;
        @media (max-width:  768px) {
            display: flex;
            justify-content: center;
            margin-bottom: 20px;
            .avatar-img {
                width: 120px;
                height: 120px;
            }
        }
    }
}

.hire-baner-account {
    position: relative;
    width: 295px;
    align-self: start;
    padding: 20px;
    border: 2px solid $main-color;
    border-radius: $main-border-radius;
    box-shadow: 4px 8px 40px rgba(0, 0, 0, .05);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: $white;
    @media (max-width: 1200px) {
        width: 100%;
        padding: 25px;
        margin-bottom: 20px;
    }
}
</style>
