<template>
    <svg
        width="95"
        height="70"
        viewBox="0 0 95 70"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11.668 10.4167C11.668 5.23908 15.8653 1.04175 21.043 1.04175H85.1055C90.2831 1.04175 94.4805 5.23908 94.4805 10.4167V59.8959C94.4805 65.0736 90.2831 69.2709 85.1055 69.2709H21.043C15.8653 69.2709 11.668 65.0736 11.668 59.8959V53.7718C11.668 52.9089 12.3675 52.2093 13.2305 52.2093C14.0934 52.2093 14.793 52.9089 14.793 53.7718V59.8959C14.793 61.6223 15.4929 63.1852 16.6245 64.3163L47.1884 38.57C47.8484 38.0141 48.8341 38.0984 49.39 38.7584C49.946 39.4184 49.8617 40.4041 49.2017 40.9601L19.5238 65.96C20.0101 66.0814 20.519 66.1459 21.043 66.1459H85.1055C85.3485 66.1459 85.5883 66.132 85.8242 66.105L55.9741 40.9601C55.3141 40.4041 55.2298 39.4184 55.7857 38.7584C56.3417 38.0984 57.3274 38.0141 57.9874 38.57L89.0547 64.7404C90.4589 63.5943 91.3555 61.8498 91.3555 59.8959V10.4167C91.3555 9.51011 91.1624 8.64856 90.8152 7.87095L54.7107 37.1907C53.6029 38.0903 52.0147 38.0851 50.9129 37.1782L15.3266 7.886C14.9835 8.65967 14.793 9.51595 14.793 10.4167V22.6563C14.793 23.5193 14.0934 24.2188 13.2305 24.2188C12.3675 24.2188 11.668 23.5193 11.668 22.6563V10.4167ZM17.2671 5.43582L52.82 34.7005L88.8685 5.42614C87.8217 4.63557 86.5183 4.16675 85.1055 4.16675H21.043C19.6243 4.16675 18.3161 4.6394 17.2671 5.43582ZM3.03831 22.2797C2.39068 21.632 1.34066 21.632 0.693026 22.2797C0.0453938 22.9273 0.0453937 23.9773 0.693026 24.625L11.3656 35.2976L0.692755 45.9705C0.0451229 46.6181 0.0451235 47.6681 0.692755 48.3158C1.34039 48.9634 2.39041 48.9634 3.03804 48.3158L13.7109 37.6429L23.9339 47.8659C24.5816 48.5135 25.6316 48.5135 26.2792 47.8659C26.9269 47.2182 26.9269 46.1682 26.2792 45.5206L16.0562 35.2976L26.279 25.0748C26.9266 24.4272 26.9266 23.3772 26.279 22.7295C25.6313 22.0819 24.5813 22.0819 23.9337 22.7295L13.7109 32.9523L3.03831 22.2797Z"
            fill="#C4C4C4"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11.668 10.4167C11.668 5.23908 15.8653 1.04175 21.043 1.04175H85.1055C90.2831 1.04175 94.4805 5.23908 94.4805 10.4167V59.8959C94.4805 65.0736 90.2831 69.2709 85.1055 69.2709H21.043C15.8653 69.2709 11.668 65.0736 11.668 59.8959V53.7718C11.668 52.9089 12.3675 52.2093 13.2305 52.2093C14.0934 52.2093 14.793 52.9089 14.793 53.7718V59.8959C14.793 61.6223 15.4929 63.1852 16.6245 64.3163L47.1884 38.57C47.8484 38.0141 48.8341 38.0984 49.39 38.7584C49.946 39.4184 49.8617 40.4041 49.2017 40.9601L19.5238 65.96C20.0101 66.0814 20.519 66.1459 21.043 66.1459H85.1055C85.3485 66.1459 85.5883 66.132 85.8242 66.105L55.9741 40.9601C55.3141 40.4041 55.2298 39.4184 55.7857 38.7584C56.3417 38.0984 57.3274 38.0141 57.9874 38.57L89.0547 64.7404C90.4589 63.5943 91.3555 61.8498 91.3555 59.8959V10.4167C91.3555 9.51011 91.1624 8.64856 90.8152 7.87095L54.7107 37.1907C53.6029 38.0903 52.0147 38.0851 50.9129 37.1782L15.3266 7.886C14.9835 8.65967 14.793 9.51595 14.793 10.4167V22.6563C14.793 23.5193 14.0934 24.2188 13.2305 24.2188C12.3675 24.2188 11.668 23.5193 11.668 22.6563V10.4167ZM17.2671 5.43582L52.82 34.7005L88.8685 5.42614C87.8217 4.63557 86.5183 4.16675 85.1055 4.16675H21.043C19.6243 4.16675 18.3161 4.6394 17.2671 5.43582ZM3.03831 22.2797C2.39068 21.632 1.34066 21.632 0.693026 22.2797C0.0453938 22.9273 0.0453937 23.9773 0.693026 24.625L11.3656 35.2976L0.692755 45.9705C0.0451229 46.6181 0.0451235 47.6681 0.692755 48.3158C1.34039 48.9634 2.39041 48.9634 3.03804 48.3158L13.7109 37.6429L23.9339 47.8659C24.5816 48.5135 25.6316 48.5135 26.2792 47.8659C26.9269 47.2182 26.9269 46.1682 26.2792 45.5206L16.0562 35.2976L26.279 25.0748C26.9266 24.4272 26.9266 23.3772 26.279 22.7295C25.6313 22.0819 24.5813 22.0819 23.9337 22.7295L13.7109 32.9523L3.03831 22.2797Z"
            fill="url(#paint0_linear_4439_4497)"
        />
        <defs>
            <linearGradient
                id="paint0_linear_4439_4497"
                x1="0.207032"
                y1="23.8489"
                x2="28.2072"
                y2="26.1569"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#FF9D39" />
                <stop
                    offset="1"
                    stop-color="#FF5925"
                />
            </linearGradient>
        </defs>
    </svg>
</template>

<script>
export default {
    name: 'SuccessfulPaymentIcon'
}
</script>
<style lang="scss">
defs > linearGradient > stop:first-child{
    stop-color: $second-gradient__color-first;
}
defs > linearGradient > stop:last-child{
    stop-color: $second-gradient__color-second;
}
</style>
