<template>
    <div class="unsubscribe">
        <div class="unsubscribe__container">
            <UnsubscribeEmail />
            <p class="unsubscribe__title">
                <span>You have successfully unsubscribed from emails.</span>
            </p>
            <p class="unsubscribe__text">
                If you unsubscribed by mistake or if you change your mind,
                you can subscribe again in your profile.
            </p>

            <div class="unsubscribe__buttons">
                <custom-button
                    default
                    class="button btn-base_colored"
                    @on-btn-click="$router.push({ name: 'login' })"
                >
                    {{ `${auth ? 'Profile': 'Sing in'}` }}
                </custom-button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

import UnsubscribeEmail from '@/components/icons/UnsubscribeEmail.vue';

import Api from '@/helpers/api/index.js';

export default {
    name: 'Unsubscribe',
    components: {
        UnsubscribeEmail
    },
    computed: {
        ...mapState('client', {
            auth: 'auth'
        })
    },
    metaInfo: {
        title: 'Unsubscribe from email'
    },
    created() {
        this.unsubscribe()
    },
    methods: {
        async unsubscribe() {
            const { token } = this.$route.params
            try {
                await Api.put('/api/quick/unsubscribe', {
                    token
                })
            } catch (error) {
                this.$router.push({ name: 'login' })
            }
        }
    }
}
</script>

<style lang="scss">
    .unsubscribe{
        width: 100vw;
        height: 70vh;
        min-height: 700px;
        background-color: #fafafa;
        &__container {
            max-width: 900px;
            height: 100%;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            padding: 0 15px;
            svg {
                fill: $main-color;
            }
        }
        &__title {
            font-style: normal;
            font-weight: 900;
            font-size: 56px;
            line-height: 70px;
            margin-top: 40px;
            margin-bottom: 20px;
            text-align: center;
            display: flex;
            flex-direction: column;
            @media all and (max-width: 992px) {
                font-size: 32px;
                line-height: 36px;
            }
        }
        &__text {
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            line-height: 40px;
            @media all and (max-width: 992px) {
                font-size: 18px;
                line-height: 22px;
            }
        }
        &__buttons {
            margin-top: 40px;
        }
    }
</style>
