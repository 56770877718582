<template>
    <svg
        width="69"
        height="69"
        viewBox="0 0 69 69"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M65.1613 46.595C64.835 44.0552 64.4715 41.5347 63.9962 39.0239C63.5488 36.6958 63.0424 34.3773 62.477 32.0685L62.3745 31.6356C61.5045 28.1338 60.5165 24.6673 59.4105 21.2361L59.2801 20.8225C58.1336 17.3111 56.8567 13.8382 55.4773 10.4327C54.5673 7.84222 53.3693 5.36935 51.9075 3.06364C50.7518 1.45707 48.9157 1.91884 47.4617 2.82313L47.0889 3.06364L47.3032 3.40995C48.6165 5.74083 49.712 8.19542 50.5747 10.7405C51.8703 14.0787 53.0726 17.4747 54.2283 20.8417C55.0019 23.0928 55.7942 25.344 56.4373 27.6432C57.4812 31.3277 58.3014 35.0892 59.1496 38.8411C60.1632 43.0761 60.9227 47.3717 61.4237 51.7033V52.1843C61.8066 52.2728 62.1967 52.3243 62.5888 52.3382C63.2348 52.3834 63.8718 52.162 64.3597 51.7225C65.6832 50.4815 65.3197 48.1919 65.1613 46.595Z"
            stroke="#FF690C"
            stroke-width="3"
        />
        <path
            d="M61.4245 51.7034C60.9234 47.3718 60.1639 43.0762 59.1503 38.8412C58.3301 35.0893 57.4819 31.3278 56.438 27.6433C55.7949 25.344 55.0026 23.0929 54.229 20.8418C53.0733 17.4555 51.871 14.1077 50.5754 10.7406C49.7127 8.1955 48.6172 5.74092 47.3039 3.41003L47.0896 3.06372C45.5543 4.21056 44.1504 5.53381 42.9047 7.008C36.3804 13.973 29.6696 20.9765 21.4117 25.7C20.3771 26.2965 19.3146 26.8352 18.2334 27.3354L18.3452 27.7587C19.8179 33.9252 21.5236 40.0341 23.2758 46.1236L23.397 46.5277C28.4226 45.7665 33.522 45.6727 38.5707 46.2487C43.0168 46.9391 47.4044 47.9844 51.6939 49.3753C54.1731 50.116 56.6524 50.8568 59.1503 51.5302C59.9052 51.7322 60.6881 52.0112 61.4804 52.1844L61.4245 51.7034Z"
            stroke="#FF690C"
            stroke-width="3"
        />
        <path
            d="M23.2753 46.1237C21.523 40.0341 19.8173 33.9253 18.3447 27.7587L18.2329 27.3354L18.0372 27.4317C14.3649 28.336 10.7082 29.298 7.06697 30.3177C5.73415 30.6929 4.06577 30.9334 2.97527 31.8858C1.88478 32.8382 1.95934 34.3871 2.04323 35.7339C2.21693 38.8396 3.03374 41.8707 4.43859 44.6229C5.15077 46.0327 6.01363 47.3557 7.01105 48.5672C7.31684 49.0589 7.77275 49.4312 8.30661 49.6254C9.06174 49.6701 9.81861 49.579 10.5435 49.3561V49.3561L10.9536 49.2887C14.0293 48.7307 17.0865 48.0702 20.125 47.307L20.5257 47.2011L22.716 46.6335L23.3591 46.5374L23.2753 46.1237Z"
            stroke="#FF690C"
            stroke-width="3"
        />
        <path
            d="M66.2517 23.8626C66.7737 25.2768 67.4261 27.0565 66.6246 28.4899C65.823 29.9233 63.8284 30.6545 62.4956 31.578L62.4024 31.6357C61.5325 28.134 60.5445 24.6675 59.4385 21.2363C60.6874 21.1209 61.9364 20.9477 63.1667 20.9285C63.4515 20.8783 63.7433 20.8891 64.0239 20.9603C64.3045 21.0314 64.5679 21.1614 64.7977 21.3422C65.4664 22.0537 65.9643 22.9168 66.2517 23.8626V23.8626Z"
            stroke="#FF690C"
            stroke-width="3"
        />
        <path
            d="M26.5833 58.4951C23.7872 55.2339 22.0535 51.1934 20.5623 47.1914L20.1615 47.2972C17.123 48.054 14.0659 48.7146 10.9902 49.279L10.5801 49.3463C11.3988 52.24 12.4333 55.064 13.6745 57.7929C14.7808 60.42 16.0996 62.9462 17.617 65.3447C18.2601 66.3067 18.8846 67.1725 20.1522 66.9705C21.7366 66.7204 23.1068 65.4313 24.4769 64.6232C25.5394 63.9882 27.0587 63.2763 27.7111 62.1315C28.522 60.7077 27.4408 59.5149 26.5833 58.4951Z"
            stroke="#FF690C"
            stroke-width="3"
        />
    </svg>
</template>

<script>
export default {
    name: 'Tell'
}
</script>
<style scoped lang='scss'>

svg {
    width: 30px;
    height: 30px;
}

svg path {
    stroke: $main-color;
}
</style>
