<template>
    <svg
        width="69"
        height="69"
        viewBox="0 0 69 69"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M34.5 67C52.4493 67 67 52.4493 67 34.5C67 16.5507 52.4493 2 34.5 2C16.5507 2 2 16.5507 2 34.5C2 52.4493 16.5507 67 34.5 67Z"
            stroke="#FF690C"
            stroke-width="3"
        />
        <path
            d="M43.1301 23.4999C43.1321 25.2072 42.6276 26.8767 41.6805 28.2972C40.7335 29.7177 39.3864 30.8254 37.8096 31.4801C36.2329 32.1348 34.4973 32.3071 32.8226 31.9753C31.1479 31.6434 29.6092 30.8223 28.4013 29.6158C27.1934 28.4092 26.3705 26.8715 26.0367 25.1972C25.7029 23.5229 25.8732 21.7871 26.5261 20.2096C27.179 18.6321 28.2851 17.2837 29.7045 16.335C31.1239 15.3863 32.7928 14.8799 34.5001 14.8799C36.7872 14.8799 38.9808 15.7877 40.5989 17.404C42.2171 19.0203 43.1275 21.2128 43.1301 23.4999V23.4999Z"
            stroke="#FF690C"
            stroke-width="3"
        />
        <path
            d="M53.65 61.5V49.98C53.792 48.4207 53.4198 46.8578 52.59 45.53C49.26 41.22 39.98 39.27 34.67 39.27C25.39 39.4 19.8 42.4 17.09 45.04C15.58 46.52 15.25 48.71 15.25 50.83V61.5"
            stroke="#FF690C"
            stroke-width="3"
        />
    </svg>
</template>

<script>
export default {
    name: 'User'
}
</script>
<style scoped lang='scss'>

svg {
    width: 30px;
    height: 30px;
}

svg path {
    stroke: $main-color;
}
</style>
