<template>
    <svg
        width="49"
        height="48"
        viewBox="0 0 49 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            x="0.333496"
            width="48"
            height="48"
            rx="24"
            fill="#FF690C"
        />
        <path
            d="M26.3335 14.2695V18.4001C26.3335 18.9601 26.3335 19.2401 26.4425 19.4541C26.5384 19.6422 26.6913 19.7952 26.8795 19.8911C27.0934 20.0001 27.3734 20.0001 27.9335 20.0001H32.064M21.3335 28L23.3335 30L27.8335 25.5M26.3335 14H21.1335C19.4533 14 18.6133 14 17.9715 14.327C17.407 14.6146 16.9481 15.0735 16.6605 15.638C16.3335 16.2798 16.3335 17.1198 16.3335 18.8V29.2C16.3335 30.8802 16.3335 31.7202 16.6605 32.362C16.9481 32.9265 17.407 33.3854 17.9715 33.673C18.6133 34 19.4533 34 21.1335 34H27.5335C29.2137 34 30.0537 34 30.6955 33.673C31.26 33.3854 31.7189 32.9265 32.0065 32.362C32.3335 31.7202 32.3335 30.8802 32.3335 29.2V20L26.3335 14Z"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>

<script>
export default {
    name: 'Plagiarism'
}
</script>
<style scoped lang='scss'>
svg {
    width: 48px;
    height: 48px;
}
svg rect {
    fill: $main-color;
}
</style>
