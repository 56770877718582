<template>
    <svg
        width="49"
        height="48"
        viewBox="0 0 49 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            x="0.333496"
            width="48"
            height="48"
            rx="24"
            fill="#FF690C"
        />
        <path
            d="M25.3335 17.9998L18.8353 19.2995C18.4723 19.3721 18.2908 19.4084 18.143 19.4967C18.0124 19.5747 17.9012 19.6815 17.8181 19.809C17.7241 19.9532 17.6806 20.1331 17.5938 20.493L14.3335 33.9998M14.3335 33.9998L27.8404 30.7396C28.2002 30.6527 28.3801 30.6093 28.5243 30.5153C28.6518 30.4321 28.7587 30.321 28.8367 30.1903C28.925 30.0425 28.9613 29.861 29.0339 29.498L30.3335 22.9998M14.3335 33.9998L21.9195 26.4138M33.2021 19.8685L28.4649 15.1312C28.0688 14.7352 27.8708 14.5372 27.6425 14.463C27.4417 14.3977 27.2253 14.3977 27.0245 14.463C26.7961 14.5372 26.5981 14.7352 26.2021 15.1312L25.4649 15.8685C25.0688 16.2645 24.8708 16.4625 24.7967 16.6908C24.7314 16.8917 24.7314 17.108 24.7967 17.3089C24.8708 17.5372 25.0688 17.7352 25.4649 18.1312L30.2021 22.8685C30.5981 23.2645 30.7962 23.4625 31.0245 23.5367C31.2253 23.6019 31.4417 23.6019 31.6425 23.5367C31.8708 23.4625 32.0689 23.2645 32.4649 22.8685L33.2021 22.1312C33.5981 21.7352 33.7962 21.5372 33.8703 21.3089C33.9356 21.108 33.9356 20.8917 33.8703 20.6908C33.7962 20.4625 33.5981 20.2645 33.2021 19.8685ZM23.3335 22.9998C24.4381 22.9998 25.3335 23.8953 25.3335 24.9998C25.3335 26.1044 24.4381 26.9998 23.3335 26.9998C22.2289 26.9998 21.3335 26.1044 21.3335 24.9998C21.3335 23.8953 22.2289 22.9998 23.3335 22.9998Z"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>

<script>
export default {
    name: 'Style'
}
</script>
<style scoped lang='scss'>
svg {
    width: 48px;
    height: 48px;
}
svg rect {
    fill: $main-color;
}
</style>
