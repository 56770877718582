<template>
    <svg
        width="72"
        height="61"
        viewBox="0 0 72 61"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M29.1807 33.139C29.1807 32.7191 29.0138 32.3163 28.7169 32.0194C28.42 31.7225 28.0173 31.5557 27.5973 31.5557H21.264C20.8441 31.5557 20.4413 31.7225 20.1444 32.0194C19.8475 32.3163 19.6807 32.7191 19.6807 33.139C19.6807 33.5589 19.8475 33.9617 20.1444 34.2586C20.4413 34.5555 20.8441 34.7223 21.264 34.7223H27.5973C28.0173 34.7223 28.42 34.5555 28.7169 34.2586C29.0138 33.9617 29.1807 33.5589 29.1807 33.139Z"
            fill="#FF690C"
        />
        <path
            d="M51.3262 31.5557H48.1595C47.7396 31.5557 47.3368 31.7225 47.0399 32.0194C46.743 32.3163 46.5762 32.7191 46.5762 33.139C46.5762 33.5589 46.743 33.9617 47.0399 34.2586C47.3368 34.5555 47.7396 34.7223 48.1595 34.7223H51.3262C51.7461 34.7223 52.1488 34.5555 52.4458 34.2586C52.7427 33.9617 52.9095 33.5589 52.9095 33.139C52.9095 32.7191 52.7427 32.3163 52.4458 32.0194C52.1488 31.7225 51.7461 31.5557 51.3262 31.5557Z"
            fill="#FF690C"
        />
        <path
            d="M37.0872 25.2329C37.5072 25.2329 37.9099 25.0661 38.2068 24.7691C38.5038 24.4722 38.6706 24.0695 38.6706 23.6496V17.3162C38.6706 16.8963 38.5038 16.4936 38.2068 16.1966C37.9099 15.8997 37.5072 15.7329 37.0872 15.7329C36.6673 15.7329 36.2646 15.8997 35.9676 16.1966C35.6707 16.4936 35.5039 16.8963 35.5039 17.3162V23.6496C35.5039 24.0695 35.6707 24.4722 35.9676 24.7691C36.2646 25.0661 36.6673 25.2329 37.0872 25.2329Z"
            fill="#FF690C"
        />
        <path
            d="M37.0864 41.045C36.6683 41.0477 36.2683 41.2158 35.9737 41.5124C35.679 41.8091 35.5137 42.2102 35.5137 42.6283V48.9616C35.5137 49.3815 35.6805 49.7843 35.9774 50.0812C36.2743 50.3782 36.6771 50.545 37.097 50.545C37.5169 50.545 37.9196 50.3782 38.2166 50.0812C38.5135 49.7843 38.6803 49.3815 38.6803 48.9616V42.6283C38.6803 42.4195 38.639 42.2127 38.5588 42.0199C38.4786 41.8272 38.361 41.6521 38.2129 41.505C38.0647 41.3578 37.8889 41.2414 37.6956 41.1624C37.5023 41.0835 37.2953 41.0436 37.0864 41.045Z"
            fill="#FF690C"
        />
        <path
            d="M27.0276 20.8417C26.886 20.6756 26.7117 20.5405 26.5155 20.4451C26.3193 20.3496 26.1054 20.2957 25.8874 20.2867C25.6694 20.2778 25.4519 20.314 25.2485 20.3932C25.0451 20.4723 24.8603 20.5926 24.7056 20.7465C24.551 20.9004 24.4298 21.0847 24.3497 21.2877C24.2696 21.4907 24.2324 21.708 24.2403 21.9261C24.2482 22.1441 24.3011 22.3583 24.3957 22.5549C24.4902 22.7516 24.6244 22.9265 24.7898 23.0689L29.2654 27.5444C29.4104 27.6939 29.5845 27.8122 29.7769 27.8921C29.9693 27.9719 30.176 28.0117 30.3843 28.0089C30.5923 28.0099 30.7984 27.9693 30.9906 27.8895C31.1827 27.8098 31.357 27.6925 31.5031 27.5444C31.7971 27.2462 31.9619 26.8443 31.9619 26.4256C31.9619 26.0068 31.7971 25.6049 31.5031 25.3066L27.0276 20.8417Z"
            fill="#FF690C"
        />
        <path
            d="M44.9191 38.7333C44.7729 38.5852 44.5986 38.4677 44.4066 38.3874C44.2145 38.3072 44.0084 38.2659 43.8003 38.2659C43.5921 38.2659 43.386 38.3072 43.194 38.3874C43.0019 38.4677 42.8277 38.5852 42.6814 38.7333C42.3874 39.0315 42.2227 39.4335 42.2227 39.8522C42.2227 40.2709 42.3874 40.6729 42.6814 40.9711L47.1569 45.4361C47.4538 45.7326 47.8562 45.8992 48.2758 45.8992C48.6954 45.8992 49.0978 45.7326 49.3947 45.4361C49.688 45.1397 49.8525 44.7395 49.8525 44.3225C49.8525 43.9055 49.688 43.5053 49.3947 43.2089L44.9191 38.7333Z"
            fill="#FF690C"
        />
        <path
            d="M29.265 38.7333L24.7895 43.2089C24.5308 43.5124 24.3961 43.9023 24.4125 44.3008C24.4288 44.6992 24.5949 45.0769 24.8776 45.3582C25.1602 45.6395 25.5386 45.8038 25.9371 45.8183C26.3357 45.8327 26.725 45.6962 27.0273 45.4361L31.5028 40.9711C31.7968 40.6729 31.9615 40.2709 31.9615 39.8522C31.9615 39.4335 31.7968 39.0315 31.5028 38.7333C31.3565 38.5852 31.1823 38.4677 30.9902 38.3874C30.7982 38.3072 30.5921 38.2659 30.3839 38.2659C30.1758 38.2659 29.9697 38.3072 29.7776 38.3874C29.5855 38.4677 29.4113 38.5852 29.265 38.7333Z"
            fill="#FF690C"
        />
        <path
            d="M66.3889 59H5.16667C4.32681 59 3.52134 58.6664 2.92748 58.0725C2.33361 57.4787 2 56.6732 2 55.8333V5.16667C2 4.32681 2.33361 3.52137 2.92748 2.92751C3.52134 2.33364 4.32681 2 5.16667 2H26.8161C27.3266 2.00084 27.8294 2.12511 28.2815 2.3622C28.7337 2.5993 29.1218 2.9422 29.4128 3.36168L31.9144 6.97166C32.2065 7.3926 32.5962 7.73641 33.0503 7.97358C33.5044 8.21075 34.0094 8.3342 34.5217 8.33333H66.3889C67.2287 8.33333 68.0342 8.66698 68.628 9.26084C69.2219 9.85471 69.5556 10.6601 69.5556 11.5V55.8333C69.5556 56.6732 69.2219 57.4787 68.628 58.0725C68.0342 58.6664 67.2287 59 66.3889 59V59Z"
            stroke="#FF690C"
            stroke-width="3"
            stroke-linecap="round"
        />
    </svg>
</template>

<script>
export default {
    name: 'LoadingOrder'
}
</script>
<style scoped lang='scss'>

svg {
    width: 30px;
    height: 30px;
}

svg path {
    stroke: $main-color;
}
</style>
