<template>
    <svg
        width="49"
        height="48"
        viewBox="0 0 49 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            x="0.666504"
            width="48"
            height="48"
            rx="24"
            fill="#FF690C"
        />
        <path
            d="M21.8795 26.5001C22.1917 27.0946 22.8151 27.5001 23.5332 27.5001H25.6665C26.7711 27.5001 27.6665 26.6047 27.6665 25.5001C27.6665 24.3955 26.7711 23.5001 25.6665 23.5001H23.6665C22.5619 23.5001 21.6665 22.6047 21.6665 21.5001C21.6665 20.3955 22.5619 19.5001 23.6665 19.5001H25.7998C26.5179 19.5001 27.1413 19.9056 27.4535 20.5001M24.6665 18.0001V19.5001M24.6665 27.5001V29.0001M32.6665 24.0001C32.6665 28.9086 27.3125 32.4785 25.3645 33.615C25.1431 33.7442 25.0324 33.8087 24.8762 33.8422C24.7549 33.8682 24.5781 33.8682 24.4568 33.8422C24.3006 33.8087 24.1899 33.7442 23.9685 33.615C22.0205 32.4785 16.6665 28.9086 16.6665 24.0001V19.2177C16.6665 18.4182 16.6665 18.0184 16.7973 17.6748C16.9128 17.3713 17.1005 17.1004 17.3442 16.8856C17.62 16.6426 17.9943 16.5022 18.7429 16.2215L24.1047 14.2108C24.3126 14.1328 24.4165 14.0938 24.5235 14.0784C24.6183 14.0647 24.7147 14.0647 24.8095 14.0784C24.9165 14.0938 25.0204 14.1328 25.2283 14.2108L30.5901 16.2215C31.3387 16.5022 31.713 16.6426 31.9888 16.8856C32.2325 17.1004 32.4202 17.3713 32.5357 17.6748C32.6665 18.0184 32.6665 18.4182 32.6665 19.2177V24.0001Z"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>

<script>
export default {
    name: 'MoneyBack'
}
</script>
<style scoped lang='scss'>
svg {
    width: 48px;
    height: 48px;
}
svg rect {
    fill: $main-color;
}
</style>
