<template>
    <svg
        width="49"
        height="48"
        viewBox="0 0 49 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            x="0.666504"
            width="48"
            height="48"
            rx="24"
            fill="#FF690C"
        />
        <path
            d="M26.6665 23H20.6665M22.6665 27H20.6665M28.6665 19H20.6665M32.6665 18.8V29.2C32.6665 30.8802 32.6665 31.7202 32.3395 32.362C32.0519 32.9265 31.593 33.3854 31.0285 33.673C30.3867 34 29.5467 34 27.8665 34H21.4665C19.7863 34 18.9463 34 18.3045 33.673C17.74 33.3854 17.2811 32.9265 16.9935 32.362C16.6665 31.7202 16.6665 30.8802 16.6665 29.2V18.8C16.6665 17.1198 16.6665 16.2798 16.9935 15.638C17.2811 15.0735 17.74 14.6146 18.3045 14.327C18.9463 14 19.7863 14 21.4665 14H27.8665C29.5467 14 30.3867 14 31.0285 14.327C31.593 14.6146 32.0519 15.0735 32.3395 15.638C32.6665 16.2798 32.6665 17.1198 32.6665 18.8Z"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>

<script>
export default {
    name: 'Instructions'
}
</script>
<style scoped lang='scss'>
svg {
    width: 48px;
    height: 48px;
}
svg rect {
    fill: $main-color;
}
</style>
