<template>
    <svg
        width="69"
        height="69"
        viewBox="0 0 69 69"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M51.3182 39.874C51.3182 36.5214 50.3318 33.2441 48.4837 30.4565C46.6357 27.6689 44.009 25.4962 40.9357 24.2132C37.8625 22.9302 34.4808 22.5945 31.2182 23.2486C27.9557 23.9026 24.9589 25.5171 22.6068 27.8877C20.2546 30.2584 18.6528 33.2788 18.0038 36.567C17.3549 39.8552 17.688 43.2636 18.9609 46.361C20.2339 49.4584 22.3897 52.1058 25.1555 53.9684C27.9213 55.8311 31.173 56.8252 34.4994 56.8252C38.9592 56.8225 43.2356 55.0357 46.3891 51.8573C49.5427 48.679 51.3155 44.3689 51.3182 39.874V39.874Z"
            stroke="#FF690C"
            stroke-width="3"
            stroke-miterlimit="10"
        />
        <path
            d="M42.9089 32.811L31.1377 46.937L26.0596 41.2866"
            stroke="#FF690C"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M63.9531 12.748H5.04688C3.36413 12.748 2 14.1229 2 15.8189V63.9291C2 65.6251 3.36413 67 5.04688 67H63.9531C65.6359 67 67 65.6251 67 63.9291V15.8189C67 14.1229 65.6359 12.748 63.9531 12.748Z"
            stroke="#FF690C"
            stroke-width="3"
            stroke-linecap="round"
        />
        <path
            d="M21.043 11.7244V6.09449C21.043 5.00856 21.471 3.96711 22.2329 3.19924C22.9948 2.43138 24.028 2 25.1055 2H43.8945C44.972 2 46.0052 2.43138 46.7671 3.19924C47.529 3.96711 47.957 5.00856 47.957 6.09449V11.7244"
            stroke="#FF690C"
            stroke-width="3"
            stroke-linecap="round"
        />
    </svg>
</template>

<script>
export default {
    name: 'CheckOrder'
}
</script>
<style scoped lang='scss'>

svg {
    width: 30px;
    height: 30px;
}

svg path {
    stroke: $main-color;
}
</style>
