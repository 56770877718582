<template>
    <div>
        <breadcrumbs
            v-if="!isWriterAccountDetails"
            :settings="settingsBreadcrumbs"
        />
        <RadiusLoader v-if="pageLoading" />
        <div
            v-else
            class="page_writer"
        >
            <div
                class="wrapper"
                :class="!isWriterAccountDetails ? 'writer-profile-wrapper' : 'writer-account-profile-wrapper'"
            >
                <div class="writer-profile">
                    <WritersCardDetails
                        :writer="writerData"
                    />
                    <WriterCardDetailsTabs
                        :items="getTabs"
                        @redirectToTab="redirectToTab"
                    />
                    <select-tabs
                        v-model="selectedTab.value"
                        :field.sync="selectedTab.value"
                        :value-select="selectedTab.value"
                        :items="getTabs"
                        name="Tabs"
                    />
                    <router-view
                        :key="$route.fullPath"
                        :writer="writerData"
                    />
                </div>
                <div
                    v-if="!isWriterAccountDetails"
                    class="writer-profile__sidebar"
                >
                    <hire-writer
                        :writer="writerData"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { eventBus } from '@/helpers/event-bus/'
import Api from '@/helpers/api/index.js'
import Breadcrumbs from '@/components/Breadcrumbs';
import WritersCardDetails from '@/components/writers/WritersCardDetails.vue'
import WriterCardDetailsTabs from '@/components/writers/WriterCardDetailsTabs.vue'
import SelectTabs from '@/components/common/SelectTabs.vue';
import HireWriter from '@/components/common/banners/HireWriter.vue'
import RadiusLoader from '@/components/common/RadiusLoader.vue'

export default {
    components: {
        Breadcrumbs,
        WritersCardDetails,
        HireWriter,
        WriterCardDetailsTabs,
        SelectTabs,
        RadiusLoader
    },
    metaInfo() {
        return {
            title: `Writer ${this.writerData.name}`
        }
    },
    data() {
        return {
            writerData: {},
            pageLoading: true,
            testOnline: false,
            selectedTab: {
                name: 'writer_about',
                text: 'About writer'
            }
        }
    },
    computed: {
        isWriterAccountDetails() {
            return this.$route.name === 'writer_account_reviews' || this.$route.name === 'writer_account_about'
        },
        settingsBreadcrumbs() {
            const { breadcrumbs } = this.$route.meta
            return {
                title: breadcrumbs.name,
                steps: [
                    {
                        title: 'Home',
                        url: '/'
                    },
                    {
                        title: 'Writers',
                        url: '/writers'
                    },
                    {
                        title: this.$route.params.id,
                        url: `/writers/profile/${this.$route.params.id}`
                    }
                ],
                excerpt: breadcrumbs.excerpt,
                additionalClass: breadcrumbs.additionalClass
            }
        },
        getTabs() {
            return [
                {
                    value: `${this.$route.name === 'writer_account_about' || this.$route.name === 'writer_account_reviews' ? 'writer_account_about' : 'writer_about'}`,
                    icon: 'AboutWriter',
                    text: 'About writer',
                    counter: false
                },
                {
                    value: `${this.$route.name === 'writer_account_about' || this.$route.name === 'writer_account_reviews' ? 'writer_account_reviews' : 'writer_reviews'}`,
                    icon: 'Reviews',
                    text: 'Reviews',
                    counter: this.writerData.statistics.rank_all_count
                }
            ]
        }
    },
    watch: {
        selectedTab: {
            handler() {
                this.redirectToTab(this.selectedTab.value)
            },
            deep: true
        }
    },
    async activated() {
        await this.getWriter()
    },
    deactivated() {
        this.$destroy()
    },
    methods: {
        async getWriter() {
            try {
                this.pageLoading = true
                const { data } = await Api.get('/api/writer/fetch', { id: this.$route.params.id })
                this.writerData = data
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error');
            } finally {
                this.pageLoading = false
            }
        },
        redirectToTab(selectTab) {
            this.$router.push({ name: selectTab, a: 'a' })
        }
    }
}
</script>

<style lang="scss">
    .wrapper.writer-profile-wrapper {
        .writer-profile {
            width: 100%;
        }
    }
    .writer-profile {
        margin-right: 20px;
        @media all and (max-width: 576px) {
            margin-right: 0;
        }

        &-wrapper {
            display: flex;
            margin-top: 20px;
            @include media768max {
                margin-top: 0;
            }
        }

        &__sidebar {
            display: flex;
            flex-direction: column;
            position: sticky;
            top: 80px;
            height: 100%;
            @media all and (max-width: 992px) {
                position: initial;
            }
        }
    }
    @media (max-width: 768px) {
        .writer-profile {
            &-wrapper {
                flex-direction: column-reverse;
            }
        }
    }

    @media (max-width: 1200px) {
        .writer-account-profile {
            &-wrapper {
                flex-direction: column-reverse;
            }
        }
    }

    .writer-profile-bg{
        background: #F2F6FF;
        padding-bottom: 80px;
    }

</style>
