<template>
    <svg
        width="69"
        height="69"
        viewBox="0 0 69 69"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M18.6688 2.48535V11.5861C18.6663 12.5401 18.3018 13.4541 17.6549 14.1287C17.008 14.8032 16.1314 15.1833 15.2167 15.186H6.48926"
            stroke="#FF690C"
            stroke-width="3"
        />
        <path
            d="M33.5552 49.1218L5.5791 65.119"
            stroke="#FF690C"
            stroke-width="3"
        />
        <path
            d="M33.5547 49.1218L62.4327 65.119"
            stroke="#FF690C"
            stroke-width="3"
        />
        <path
            d="M6.07325 33.9235V13.6288C6.07277 13.3829 6.11875 13.1394 6.20861 12.9121C6.29846 12.6848 6.43039 12.4782 6.59687 12.3041L15.9449 2.55618C16.1125 2.38 16.3119 2.24015 16.5316 2.1447C16.7513 2.04925 16.9869 2.00006 17.2249 2H60.1346C60.8659 2.00268 61.5664 2.3075 62.0826 2.84767C62.5988 3.38785 62.8886 4.11934 62.8886 4.88192V33.9235"
            stroke="#FF690C"
            stroke-width="3"
        />
        <path
            d="M2.00005 31.2442V22.0625C1.99547 21.3067 2.27784 20.5796 2.78552 20.0401L6.50923 16.1672"
            stroke="#FF690C"
            stroke-width="3"
        />
        <path
            d="M66.97 31.7091V22.5275C66.9731 22.1522 66.9044 21.78 66.7679 21.4327C66.6313 21.0854 66.4297 20.7701 66.1748 20.5051L62.4512 16.6221"
            stroke="#FF690C"
            stroke-width="3"
        />
        <path
            d="M57.7692 66.9999H11.2231C10.0151 66.9999 8.81891 66.7515 7.70295 66.2692C6.58698 65.7868 5.57312 65.0798 4.71936 64.1886C3.86559 63.2973 3.18867 62.2393 2.72726 61.0751C2.26584 59.9109 2.029 58.6633 2.03028 57.4036V31.2439L33.6138 49.1219L67.0009 31.2439V57.4036C67.0009 58.6664 66.7619 59.9167 66.2976 61.083C65.8333 62.2492 65.1529 63.3084 64.2953 64.1999C63.4376 65.0914 62.4197 65.7976 61.2998 66.2781C60.18 66.7586 58.9802 67.0038 57.7692 66.9999Z"
            stroke="#FF690C"
            stroke-width="3"
        />
    </svg>
</template>

<script>
export default {
    name: 'FinishOrder'
}
</script>
<style scoped lang='scss'>

svg {
    width: 30px;
    height: 30px;
}

svg path {
    stroke: $main-color;
}
</style>
