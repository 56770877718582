<template>
    <div class="friend-main-sidebar">
        <span />
    </div>
</template>

<script>
export default {
    name: 'FriendsMainSidebar'
}
</script>

<style lang="scss">

.friend-main-sidebar {
    display: $login-additional-image-block;
    color: #1f2939;
    width: 50%;
    span {
        width: 100%;
        background: $login-additional-image;
        background-size: contain;
        background-position: center;
    }
    @include media992max {
        display: none;
    }
}

</style>
