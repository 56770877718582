<template>
    <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            width="48"
            height="48"
            rx="24"
            fill="#FF690C"
        />
        <path
            d="M28 17L30 19L34 15M34 24V29.2C34 30.8802 34 31.7202 33.673 32.362C33.3854 32.9265 32.9265 33.3854 32.362 33.673C31.7202 34 30.8802 34 29.2 34H18.8C17.1198 34 16.2798 34 15.638 33.673C15.0735 33.3854 14.6146 32.9265 14.327 32.362C14 31.7202 14 30.8802 14 29.2V18.8C14 17.1198 14 16.2798 14.327 15.638C14.6146 15.0735 15.0735 14.6146 15.638 14.327C16.2798 14 17.1198 14 18.8 14H24M14.1455 31.9263C14.6147 30.2386 16.1626 29 17.9998 29H24.9998C25.9291 29 26.3937 29 26.7801 29.0769C28.3669 29.3925 29.6073 30.6329 29.9229 32.2196C29.9998 32.606 29.9998 33.0707 29.9998 34M26 21.5C26 23.7091 24.2091 25.5 22 25.5C19.7909 25.5 18 23.7091 18 21.5C18 19.2909 19.7909 17.5 22 17.5C24.2091 17.5 26 19.2909 26 21.5Z"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>

<script>
export default {
    name: 'Privacy'
}
</script>
<style scoped lang='scss'>
svg {
    width: 48px;
    height: 48px;
}
svg rect {
    fill: $main-color;
}
</style>
