<template>
    <div class="our_guarantees">
        <div class="wrapper">
            <h2 class="our_guarantees--title">
                Our guarantees
            </h2>
            <p class="our_guarantees--subtitle">
                Here’s what you are guaranteed as our customer. Pushing boundaries - for your ultimate experience.
            </p>
            <ul class="our_guarantees--list">
                <li
                    v-for="item in list"
                    :key="item.title"
                    class="our_guarantees--list-item"
                >
                    <component :is="item.icon" />
                    <p class="our_guarantees--list-item-title">
                        {{ item.title }}
                    </p>
                    <p class="our_guarantees--list-item-message">
                        {{ item.message }}
                    </p>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import Quality from '@/components/icons/OurGuarantees/Quality.vue';
import Delivery from '@/components/icons/OurGuarantees/Delivery.vue';
import Plagiarism from '@/components/icons/OurGuarantees/Plagiarism.vue';
import Privacy from '@/components/icons/OurGuarantees/Privacy.vue';
import MoneyBack from '@/components/icons/OurGuarantees/MoneyBack.vue';
import Support from '@/components/icons/OurGuarantees/Support.vue';
import DataSecurity from '@/components/icons/OurGuarantees/DataSecurity.vue';
import Instructions from '@/components/icons/OurGuarantees/Instructions.vue';
import Style from '@/components/icons/OurGuarantees/Style.vue';

export default {
    name: 'OurGuarantees',
    component: {
        Quality,
        Delivery,
        Plagiarism,
        Privacy,
        MoneyBack,
        Support,
        DataSecurity,
        Instructions,
        Style
    },
    computed: {
        list() {
            return [
                {
                    title: 'A+ Quality',
                    message: 'Excellent quality is provided by a qualified subject writer and double-checked by an editor. ',
                    icon: Quality
                },
                {
                    title: 'On-time delivery',
                    message: 'Timing is everything. We follow requested deadlines and strive to deliver papers even ahead of the schedule. ',
                    icon: Delivery
                },
                {
                    title: 'Plagiarism free',
                    message: 'You are excluded from any plagiarism drama because we check all papers twice to prove their 100% originality.',
                    icon: Plagiarism
                },
                {
                    title: 'Full Privacy',
                    message: 'Sensitive information provided to us remains strictly confidential. Our Privacy policy follows GDPR & CCPA.',
                    icon: Privacy
                },
                {
                    title: 'Money-back',
                    message: 'If we fail to satisfy your needs, please request free revisions or a refund within the first two weeks.',
                    icon: MoneyBack
                },
                {
                    title: 'Live Support',
                    message: 'The customer support team works without time-offs. You can chat and talk with our managers 24/7/365.',
                    icon: Support
                },
                {
                    title: 'Data Security',
                    message: 'All operations conducted on the website are SSL encrypted to provide you with safe payments, private chats.',
                    icon: DataSecurity
                },
                {
                    title: 'Per Instructions',
                    message: 'We fulfill your length and academic level requirements to write the paper per your custom instructions.',
                    icon: Instructions
                },
                {
                    title: 'Style Imitation',
                    message: 'Our experts can imitate your unique style so that the paper sounds just like you with academic writing.',
                    icon: Style
                }
            ]
        }
    }
}
</script>

<style lang="scss">
.our_guarantees {
    padding: 50px 0 80px;
    background: #F2F6FF;

    &--title {
        text-align: center;
        font-weight: 700;
        font-size: 32px;
        line-height: 45px;
    }
    &--subtitle {
        text-align: center;
        font-size: 18px;
        line-height: 26px;
    }
    &--list {
        margin-top: 50px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
        @media (max-width:768px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media (max-width:576px) {
            grid-template-columns: 1fr;
        }
        &-item {
            padding: 19px;
            background: #fff;
            >svg {
                margin-bottom: 18px;
            }
            &-title {
                margin-bottom: 8px;
                font-weight: 700;
                font-size: 26px;
                line-height: 36px;
            }
            &-message {
                font-size: 18px;
                line-height: 26px;
            }
        }
    }
}
</style>
