<template>
    <svg
        width="29"
        height="29"
        viewBox="0 0 29 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M26.5832 13.3883V14.4999C26.5817 17.1056 25.7379 19.641 24.1778 21.728C22.6176 23.8149 20.4246 25.3417 17.9259 26.0805C15.4271 26.8193 12.7565 26.7305 10.3123 25.8275C7.86812 24.9245 5.7813 23.2556 4.36308 21.0697C2.94487 18.8838 2.27125 16.298 2.4427 13.698C2.61414 11.0979 3.62146 8.62298 5.31443 6.64221C7.00739 4.66145 9.29529 3.281 11.8369 2.70676C14.3785 2.13252 17.0377 2.39524 19.4178 3.45575"
            stroke="#FF690C"
            stroke-width="1.8"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M26.5833 4.83325L14.5 16.9287L10.875 13.3037"
            stroke="#FF690C"
            stroke-width="1.8"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>
<script>
export default {
    name: 'CheckCircle'
}
</script>
<style scoped lang='scss'>
svg {
    width: 29px;
    height: 29px;
}
svg path {
    stroke: $main-color;
}
</style>
