<template>
    <svg
        width="49"
        height="48"
        viewBox="0 0 49 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            x="0.333496"
            width="48"
            height="48"
            rx="24"
            fill="#FF690C"
        />
        <path
            d="M34.3335 29V25C34.3335 19.4772 29.8563 15 24.3335 15C18.8106 15 14.3335 19.4772 14.3335 25V29M19.8335 33C18.4528 33 17.3335 31.8807 17.3335 30.5V27.5C17.3335 26.1193 18.4528 25 19.8335 25C21.2142 25 22.3335 26.1193 22.3335 27.5V30.5C22.3335 31.8807 21.2142 33 19.8335 33ZM28.8335 33C27.4528 33 26.3335 31.8807 26.3335 30.5V27.5C26.3335 26.1193 27.4528 25 28.8335 25C30.2142 25 31.3335 26.1193 31.3335 27.5V30.5C31.3335 31.8807 30.2142 33 28.8335 33Z"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>

<script>
export default {
    name: 'Support'
}
</script>
<style scoped lang='scss'>
svg {
    width: 48px;
    height: 48px;
}
svg rect {
    fill: $main-color;
}
</style>
