<template>
    <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            width="48"
            height="48"
            rx="24"
            fill="#FF690C"
        />
        <path
            d="M19 34V23M14 25V32C14 33.1046 14.8954 34 16 34H29.4262C30.907 34 32.1662 32.9197 32.3914 31.4562L33.4683 24.4562C33.7479 22.6389 32.3418 21 30.5032 21H27C26.4477 21 26 20.5523 26 20V16.4658C26 15.104 24.896 14 23.5342 14C23.2093 14 22.915 14.1913 22.7831 14.4881L19.2639 22.4061C19.1034 22.7673 18.7453 23 18.3501 23H16C14.8954 23 14 23.8954 14 25Z"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>

<script>
export default {
    name: 'Quality'
}
</script>
<style scoped lang='scss'>
svg {
    width: 48px;
    height: 48px;
}
svg rect {
    fill: $main-color;
}
</style>
